import UnsuscribeService from '@/services/unsuscribe.service.js'
import { UnsubscribeForm } from '@/classes/UnsubscribeForm.js'
import EventBus from '@/util/EventBus'
import ButtonMultimedia from '@/components/MultimediaManager/ButtonMultimedia/ButtonMultimedia.vue'
import getEnv from '@/util/env'
import { Color } from '@/util/Color'

export default {
   name: 'UnsubscribeForm',
   props: {
    editMode: {
      required: false,
      type: Boolean,
      default: false,
      },
      pageId: {
        required: false,
      },
      unsubscribe: {
        required: false,
      },
      isCurrentChannel: {
        required: false,
      },
    },
   components: { ButtonMultimedia },
   data () {
    return {
        unsubscribeForm: new UnsubscribeForm(),
        imageSelection: 'logo',
        apiUrl: getEnv('backend_endpoint'),
        ready: true,
        togglePreview: 0,
        Color: Color,
        selectedSmsUbsubscribePage: null,
        smsUbsubscribePages: [],
        showNewPage: false,
        newPageName: '',
        isNewPage: false,
        removePageDialog: false,
      }
    },
    mounted () {
      this.getPage()
      this.getPagesOptions()
      EventBus.$on('multimediaSelected', this.updateImage)
    },
    unmounted () {
      EventBus.$off('multimediaSelected', this.updateImage)
    },
    computed: {
      readyPreview () {
        if (
          (this.unsubscribeForm.headerText !== '') ||
          (this.unsubscribeForm.subheaderText !== '') ||
          (this.unsubscribeForm.buttonText !== '') ||
          (this.unsubscribeForm.confirmHeaderText !== '') ||
          (this.unsubscribeForm.confirmSubheaderText !== '')
        ) {
          return false
        }
        return true
      },
    },
    methods: {
      createNewPage () {
        this.isNewPage = true
        this.unsubscribeForm = new UnsubscribeForm()
        this.showNewPage = true
      },

      cancelNewPage () {
        this.showNewPage = false
        this.newPageName = ''
        this.isNewPage = false
        this.getPagesOptions()
      },

      addNewPage () {
        EventBus.$emit('showLoading', true, this.$t('Creando página...'))
        this.checksAndParse()
        UnsuscribeService.newPage({ type: this.unsubscribe.type, name: this.newPageName, unsubscribe: this.unsubscribe }).then((data) => {
          this.smsUbsubscribePages.push({ text: this.newPageName, value: data.id })
          this.showNewPage = false
          this.newPageName = ''
          this.selectedSmsUbsubscribePage = this.smsUbsubscribePages[this.smsUbsubscribePages.length - 1]
          this.getPage()
          EventBus.$emit('showLoading', false)
          this.isNewPage = false
        })
      },

      removeLogo () {
        this.unsubscribeForm.logo = null
      },
      removeBackground () {
        this.unsubscribeForm.background = null
      },
      selectLogo () {
        this.imageSelection = 'logo'
        EventBus.$emit('showMultimedia', 'imagenes', false)
      },
      selectBackGround () {
        this.imageSelection = 'background'
        EventBus.$emit('showMultimedia', 'imagenes', false)
      },

      getPagesOptions () {
        this.smsUbsubscribePages = [{ text: this.$t('Predeterminado').toString(), value: undefined }]
        UnsuscribeService.getPagesOptions({ type: this.unsubscribe.type }).then((data) => {
          data.options.forEach(option => {
            this.smsUbsubscribePages.push({ text: option.name, value: option.id })
          })
          this.selectedSmsUbsubscribePage = this.smsUbsubscribePages[0]

          if (this.pageId) {
            const found = this.smsUbsubscribePages.filter(page => page.value === parseInt(this.pageId))
            console.log(found, this.smsUbsubscribePages)
            if (found.length > 0) {
              this.selectedSmsUbsubscribePage = found[0]
            }
          }
          this.getPage()
        })
      },

      getPage () {
        const id = this.selectedSmsUbsubscribePage ? this.selectedSmsUbsubscribePage.value : undefined
        UnsuscribeService.getPage({ type: this.unsubscribe.type, id: id }).then((data) => {
            if (data.unsubscribePage != null) {
                this.unsubscribe.setData(data.unsubscribePage)
                this.unsubscribeForm.setData(JSON.parse(data.unsubscribePage.json))
            }
        })
      },

      checksAndParse () {
        this.unsubscribe.logo = this.unsubscribeForm.logo ? '/' + this.unsubscribeForm.logo : null
        this.unsubscribe.background = this.unsubscribeForm.background ? '/' + this.unsubscribeForm.background : null
        this.unsubscribe.json = JSON.stringify(this.unsubscribeForm)
      },

      save () {
        if (this.isNewPage) {
          this.addNewPage()
          return
        }
        this.checksAndParse()
        UnsuscribeService.save(this.unsubscribe).then(() => {
            EventBus.$emit('showAlert', 'success', this.$t('Se ha guardado la pagina de baja correctamente'))
        }, () => {
            EventBus.$emit('showAlert', 'success', this.$t('Hubo un error al guardar la pagina de baja'))
            this.getPage()
        })
      },
      removePage () {
        EventBus.$emit('showLoading', true, this.$t('Eliminando página...'))
        UnsuscribeService.removePage({ id: this.selectedSmsUbsubscribePage.value }).then(() => {
          this.selectedSmsUbsubscribePage = this.smsUbsubscribePages[0]
          this.getPagesOptions()
          EventBus.$emit('showLoading', false)
          this.removePageDialog = false
        })
      },

      updateImage (url, type) {
        if (this.isCurrentChannel) {
            if (this.imageSelection === 'logo') {
                this.unsubscribeForm.logo = url
            }

            if (this.imageSelection === 'background') {
                this.unsubscribeForm.background = url
            }
        }
      },
    },
}
